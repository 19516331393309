<template>
   <div>
        <Header :customstyle="{ background: '#0C1913' }">
            <template slot="title">活跃度</template>
        </Header>
        <div class="content">
            <div class="conteibutebg">
                <div class="num">{{totalActive}}</div>
                <div class="txt">我的活跃度</div>
            </div>
            <div class="menubtn">
                <div class="btn">
                    <div :class="type===1 ? 'getbtn': 'consumptionbtn'" @click="changeMenu(1)">
                        获得
                    </div>
                    <div :class="type===2 ? 'getbtn': 'consumptionbtn'" @click="changeMenu(2)">
                        消耗
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="datalist">
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                        <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多记录了"
                        @load="onLoad"
                        >
                            <div class="cell" >
                                <!--<div class="date">{{record.timestamp}}</div>-->
                                 <div class="detail" v-for="(item, num) in dataList" :key="num">
                                    <div class="topnum">
                                        <div class="num">
                                            {{parseFloat(item.amount).toFixed(4)}}
                                        </div>
                                        <div class="txt" v-if="item.type==13">
                                            类型:活跃获得
                                        </div>
                                        <div class="txt" v-if="item.type==14">
                                            类型:活跃减少
                                        </div>
                                    </div>
                                    <div class="topnum">
                                        <div class="time">
                                            时间:{{item.timestamp}}
                                        </div>
                                        <div class="time">

                                        </div>
                                    </div>
                                    <div class="status">
                                        {{ getTransferTxt (item.transfer_status)}}
                                    </div>
                                 </div>
                            </div>
                        </van-list>
                    </van-pull-refresh>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import Header from "../../components/header.vue";
import {activeLog} from '@/request/api'
export default {
    components: {
        Header
    },
    data() {
        return {
            totalActive: 0,
            type: 1,
            currentPage: 1,
            refreshing: false,
            loading: false,
            finished: false,
            dataList: [
                
            ],
            address:'',
        }
    },
    activated() {
        this.address = sessionStorage.getItem("address");
    },
    computed : {
        getTransferTxt(){
            return val => {
                let txt = ''
                switch (val) {
                    case -1:
                        txt = '失败(拒绝)'
                        break
                    case 0:
                        txt = '等待处理'
                        break
                    case 1:
                        txt = '成功'
                        break
                    case 2:
                        txt = '转账中'
                        break
                }
                return txt
            }
        }
    },
    methods: {
        changeMenu(index) {
            this.type = index
            this.onRefresh()
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.currentPage = 1;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
        onLoad() {
            if(this.address){
                this.getDataList()
                this.currentPage++;
            }else{
                this.loading = false;
            }
        },
        getDataList() {
            const params = {
                address:this.address,
                type:this.type,
                limit: 20,
                page:this.currentPage
            }
            activeLog(params).then((res)=>{
                if (this.refreshing) {
                    this.listdata = [];
                    this.refreshing = false;
                }
                this.totalActive = res.data.totalActive
                this.dataList = res.data.list
                this.loading = false
                if(res.data.list.length < 20){
                     this.finished = true;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    background: #0C1913;
    min-height: 100vh;
    padding: 32px;
    .conteibutebg {
        width: 686px;
        height: 168px;
        border-radius: 8px;
        opacity: 1;
        background: linear-gradient(180deg, rgba(25,67,21,1) 0%, rgba(16,43,29,1) 20%, rgba(16,43,29,1) 100%);
        box-shadow: 0 8px 8px 0 rgba(0,30,14,1);
        padding: 32px 0;
        .num {
            color: rgba(182,217,195,1);
            font-size: 44px;
            font-weight: 500;
            font-family: "DIN";
            text-align: center;
            line-height: 60px;
        }
        .txt {
            color: rgba(139,166,149,1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 36px;
        }
    }
    .menubtn {
        margin-top:52px;
        display: flex;
        justify-content: center;
        .btn {
            display: flex;
            width: 320px;
            height: 72px;
            background: rgba(32,40,35,1);
            border-radius: 24px;
            .getbtn {
                width: 160px;
                height: 72px;
                border-radius: 24px;
                opacity: 1;
                background: rgba(39,204,127,1);
                color: rgba(13,25,18,1);
                font-size: 32px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: center;
                line-height: 72px;
            }
            .consumptionbtn {
                width: 160px;
                height: 72px;
                border-radius: 24px;
                opacity: 1;
                color: rgba(182,217,195,1);
                font-size: 32px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: center;
                line-height: 72px;
            }
        }
    }
    .card {
        margin-top: 48px;
        width: 686px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(32,40,35,1);
        .datalist {
            padding: 24px;
            .date {
                margin-top:32px;
                color: rgba(139,166,149,1);
                font-weight: 400;
                text-align: left;
                line-height: 52px;
                font-size: 40px;
            }
            .detail {
                display: flex;
                justify-content: space-between;
                .topnum {
                    margin-top: 32px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    .num {
                        color: rgba(214,255,227,1);
                        font-size: 44px;
                        font-weight: 500;
                        font-family: "DIN";
                    }
                    .txt {
                        color: rgba(139,166,149,1);
                        font-size: 24px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                        text-align: center;
                        line-height: 36px;
                    }
                    .time {
                        color: rgba(139,166,149,1);
                        font-size: 28px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                    }
                }
                .status {
                    color: rgba(39,204,127,1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

</style>